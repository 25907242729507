import FormFooter from "./formFooter";
import bannerLogo from '../../assets/images/CLUBEZ_Login_logo.png';
import './nominationForm.css';
import ImageCropper from "../global/imageCropper";
import { useState, useContext, useEffect } from "react";
import { signatureContext } from "../../context/context";

const NominationForm = (props) => {

    const {setMembershipPhotoBlob, membershipPhotoBlob, setAdminPhotoBlob, adminPhotoBlob, updateGroupDetails, groupDetails, adminDetails, updateAdminDetails, primaryDetails, accessDetails} = props;

    const [billingCropperData,          setBillingCropperData]               = useState(null);
    const [membershipCropperData,       setMembershipCropperData]            = useState(null);
    const [accountsCropperData,         setAccountsCropperData]              = useState(null);
    const [presidentCropperData,        setPresidentCropperData]             = useState(null);
 
    const signatureImg = useContext(signatureContext)

    const openMembershipSignatureCropper = async(e) =>{
        e.preventDefault()
        setMembershipCropperData({
            imageRatio  : { width : 130, height : 35},
            dataHandler : (val) =>  setMembershipPhotoBlob(val),
            header : 'Upload Signature'
        })
    };

    const removeMembershipSignatureHandler = async(e) => {
		e.preventDefault();
		setMembershipPhotoBlob(null)
        updateAdminDetails('MembershipSignature',  '')

	};

    const openAccountsSignatureCropper = async(e) =>{
        e.preventDefault()
        setAccountsCropperData({
            imageRatio  : { width : 130, height : 35},
            dataHandler : (val) =>  setAdminPhotoBlob(val),
            header : 'Upload Signature'
        })
    };

    const removeAccountsSignatureHandler = async(e) => {
		e.preventDefault();
		setAdminPhotoBlob(null)
        updateAdminDetails('AdminSignature',  '')

	};

    return(
        <>
            <div className="panel panel-paper">
                <div className="panel-body form-onboarding">
                    <div className="panel-heading text-center mb-20">
                        {/* Logo and club name */}
                        <img className="img-responsive img-login margin-b10 banner-logo" src={bannerLogo} alt="" />
                        <h3 className="panel-title mb-0 padding-t0"><strong>MEMBERSHIP AGREEMENT</strong></h3>
                    </div>
                    <fieldset className="primary">
                        <legend> </legend>
                        <div>
                            <h4><strong>User Acknowledgment</strong></h4>
                            <h5><strong>By signing below, you acknowledge that you have read, understood, and agree to abide by the terms and conditions of the Club Agreement.</strong></h5>
                            <p className="agreement-document flex-wrap">
                                MR./MRS./ &#160;&#160;
                                <input type="text" className="form-control wide-input" id="inputFirstName" disabled value={ primaryDetails?.FirstName && primaryDetails?.LastName ?  primaryDetails.FirstName + ' ' + primaryDetails.LastName : ''} />
                                I ACCEPT ALL ABOVE TERMS & CONDITIONS OF CLUB MEMBERSHIP AGREEMENT.
                            </p>
                            <div className="agreement-document flex-center">
                                APPLICANT’S SIGN:
                                {(signatureImg) ? (
                                    <div className="avatar__holder margin-b20" style={{ backgroundImage: `url(${signatureImg.slice(0, 5) != 'https' ? URL.createObjectURL(signatureImg) : signatureImg})`, backgroundSize: "cover", backgroundPosition: "center" }}></div>
                                ) : (
                                    <div className="signature-placeholder"></div>
                                )}
                                <span className="ml-23">DATE:&#160;&#160;&#160;</span>
                                <input type="date" className="form-control date-input" id="inputDate" name="date" value={groupDetails.Date ? moment(groupDetails.Date).format('YYYY-MM-DD') : ''} onChange={(event) => updateGroupDetails('Date', event.target.value)} />
                            </div>
                            <div className={`${accessDetails.AdminID == 0 ? 'disabled-overlay' : ''} padding-t100`}>
                                <h4><strong>FOR ADMINISTRATIVE USE ONLY</strong></h4>
                                <div className="flex-space-between pt-80">
                                    <div className="agreement-document">
                                        <div className="flex-space-between align-center">
                                            <p className="margin-b0">CHECKED BY: &#160;</p>
                                            {(membershipPhotoBlob || adminDetails.MembershipSignature) ? (
                                                <div className="avatar__holder" style={{ backgroundImage: `url(${membershipPhotoBlob ? URL.createObjectURL(membershipPhotoBlob) : adminDetails.MembershipSignature && adminDetails.MembershipSignature })`, backgroundSize: "cover", backgroundPosition: "center" }}></div>
                                            ) : (
                                                <button type="button" className="btn btn-default upload-button" onClick={openMembershipSignatureCropper} disabled={accessDetails.AdminID != 1 }> Signature</button>
                                            )}
                                            {((!!membershipPhotoBlob || adminDetails.MembershipSignature) && (accessDetails.AdminType == 'Membership' || accessDetails.AdminID == 1)) && (
                                                <span className="nomi-Sign__cancel" data-toggle="kt-tooltip" data-original-title="Cancel avatar" onClick={removeMembershipSignatureHandler}>
                                                    <div className="pencil-container margin-t10">
                                                        <i className="fa fa-times"></i>
                                                        <span className="add-text">Delete</span>
                                                    </div>
                                                </span>
                                            )}
                                        </div>
                                        <p className="padding-l30 margin-b0">MEMBERSHIP DEPARTMENT</p>
                                    </div>
                                    <div className="agreement-document">
                                        <div className="padding-l20">
                                            {(adminPhotoBlob || adminDetails.AdminSignature) ? (
                                                <div className="avatar__holder" style={{ backgroundImage: `url(${adminPhotoBlob ? URL.createObjectURL(adminPhotoBlob) : adminDetails.AdminSignature && adminDetails.AdminSignature })`, backgroundSize: "cover", backgroundPosition: "center" }}></div>
                                            ) : (
                                                <button type="button" className="btn btn-default upload-button" onClick={openAccountsSignatureCropper} disabled={accessDetails.AdminID != 2 || !adminDetails.MembershipSignature}> Signature</button>
                                            )}
                                            {((!!adminPhotoBlob || adminDetails.AdminSignature) && (accessDetails.AdminType == 'Admin'  || accessDetails.AdminID == 2)) && (
                                                <span className="nomi-Sign__cancel_account" data-toggle="kt-tooltip" data-original-title="Cancel avatar" onClick={removeAccountsSignatureHandler}>
                                                    <div className="pencil-container margin-l120 signature_bottom">
                                                        <i className="fa fa-times"></i>
                                                        <span className="add-text">Delete</span>
                                                    </div>
                                                </span>
                                            )}
                                        </div>
                                        <p>ADMIN DEPARTMENT</p>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </fieldset>
                </div>
                <FormFooter num={8}/>
            </div>
            {billingCropperData && (
                <ImageCropper
                    show           = { billingCropperData }
                    header         = { billingCropperData.header }   
                    imageRatio     = { billingCropperData.imageRatio }
                    dataHandler    = { billingCropperData.dataHandler }
                    onDismissModal = {() => setBillingCropperData(null) }
                />
            )}
            {membershipCropperData && (
                <ImageCropper
                    show           = { membershipCropperData }
                    header         = { membershipCropperData.header }   
                    imageRatio     = { membershipCropperData.imageRatio }
                    dataHandler    = { membershipCropperData.dataHandler }
                    onDismissModal = {() => setMembershipCropperData(null) }
                />
            )}
            {accountsCropperData && (
                <ImageCropper
                    show           = { accountsCropperData }
                    header         = { accountsCropperData.header }   
                    imageRatio     = { accountsCropperData.imageRatio }
                    dataHandler    = { accountsCropperData.dataHandler }
                    onDismissModal = {() => setAccountsCropperData(null) }
                />
            )}
            {presidentCropperData && (
                <ImageCropper
                    show           = { presidentCropperData }
                    header         = { presidentCropperData.header }   
                    imageRatio     = { presidentCropperData.imageRatio }
                    dataHandler    = { presidentCropperData.dataHandler }
                    onDismissModal = {() => setPresidentCropperData(null) }
                />
            )}
        </>
    )
};

export default NominationForm;