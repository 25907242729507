import { useEffect, useState } from "react";
import swal from 'sweetalert2';
import defaultUser from "../../assets/images/defaultlogo.jpg";
import { getUsersGuest, deleteUsersGuest } from "../api/teeTimeAPI";
import { getAuthState } from "../../assets/scripts/login-util";
import AddGuest from "./addGuest";


const GuestModal =(props)=> {

    const [openModal, setOpenModal] = useState(false);
    const [allGuest, setAllGuest]   = useState([]);

    const [filteredGuest, setFilteredGuest] = useState([]);

    useEffect(() => {
        $('#modalGuest').modal({ backdrop: 'static', keyboard: false });
        $(props.from).addClass('modal-blur'); 
        $('#modalGuest').on('hidden.bs.modal', function () {           
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal(false);
        });

        fetchUsersGuest();      
    }, [])

    const fetchUsersGuest = async()=> {
        try {
            const { user }        = getAuthState();
            const { UsersGuests } = await getUsersGuest(user.ID);
           
            if(props?.members.length > 0){
                const filtered = UsersGuests.filter( g => props.members.findIndex( f => ((f.UserID == g.ID) && (f.UserType == 'UsersGuest'))) === -1 );
                setAllGuest(filtered);                                    
                setFilteredGuest(filtered);                                    
            }else{
                setAllGuest(UsersGuests);                                    
                setFilteredGuest(UsersGuests);                                    
            }          
        } catch (err) {
            swal.fire({icon: "error", titleText: "Error!", text: err.message})
            return;  
        }
    }

    const deleteGuest = async(m)=> {                  
        try {                     
            await deleteUsersGuest(m.ID);
            
            const filtered = filteredGuest.filter( g => g.ID != m.ID );
            const AllFiltered = allGuest.filter( g => g.ID != m.ID );
        
            setFilteredGuest(filtered); 
            setAllGuest(AllFiltered);

            swal.fire({
                icon: "success", toast: true, timer: 2500, position: "bottom-right",
                text: "guest deleted successfully", showConfirmButton: false
            });
        } catch (err) {
            $("#btnAddGuest").attr("disabled", false);
            swal.fire({icon: "error", titleText: "Error!", text: err.message})
            return;  
        }
    }

    const onSelectGuest = (player) => {            
        props.setMembers(d => [...d, { UserID: player.ID, UserName: `${player.Name} (G)`, UserType: "UsersGuest" }]);
        
        setTimeout(() => {
            $('#modalGuest').modal('hide');
            $(props.from).removeClass('modal-blur');
        },50);
    }

    const onGuestSearch = (searchTerm) => {
        if(searchTerm ==='') return setFilteredGuest(allGuest);

        //filter as per searched
        const filtered = allGuest.filter(m => {
            let matchString = `${m.Name} ${m.Mobile} ${m?.Email}`;
            let matchFound  = 0;
            var searchArr   = searchTerm.split(' ');
            
            searchArr.forEach(t => matchFound += matchString.toLowerCase().includes(t.toLowerCase()) ? 1 : 0);

            return matchFound === searchArr.length;
        });
                  
        setFilteredGuest(filtered);
    }   

    const onClose = () => $(props.from).removeClass('modal-blur');

    return (<>
        <div id="modalGuest" className="modal fade modal-responsive" tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header padding-b0">
                        <button type="button" className="close" data-dismiss="modal"> <span aria-hidden="true" onClick={onClose}> &times; </span> </button>
                        <h5 className="modal-title" id="member-modal-title"> Search Guest </h5>
                    </div>
                    <div className="modal-body overflow-x padding-t0">
                        <div id="ModalGuestHolder">
                            <div className="row sticky-search">
                                <div className="col-md-9 col-sm-8 col-xs-5">
                                    <div className="form-group">
                                        <input type="text" id="txtGuest" name="txtGuest" className="form-control"  placeholder="Search Guest..." 
                                           onChange={({target}) => onGuestSearch(target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-4 col-xs-7 padding-t10">
                                    <button type="button" className="btn btn-sm btn-default margin-r10" id="btnSearch" data-tab="search" >Search </button>
                                    <button type="button" className="btn btn-sm btn-success" id="btnAdd" onClick={() => setOpenModal(true)} > New Guest </button>
                                </div>                              
                            </div>
                                                     
                            <div className="row" id="memberContainer">
                                <div className="col-sx-12">
                                    <table id="tableGuest" className="table table-bordered table-hover">
                                        <tbody>
                                            {(filteredGuest.length > 0 ) && filteredGuest.map((m,i) => (
                                                <tr key={i}>
                                                    <td>
                                                        <div className="media member-slot" >
                                                            <div className="media-left">
                                                                <img src={defaultUser} alt="user-profile" className="media-object img-circle img-user-list-sm"/>                                                                                                                           
                                                            </div>
                                                            <div className="media-body vertical-align-middle">
                                                                <div className="row pointer">
                                                                    <div className="col-sm-10" onClick={() => onSelectGuest(m)}>
                                                                        <p className="user-detail margin-b0"> <span> {m.Name} </span> </p>
                                                                    </div>
                                                                    <div className="col-sm-2">
                                                                        <button className="btn btn-xs btn-dark margin-r10 media-actions" type="button" onClick={() => setOpenModal(m)}>                 
                                                                            <i className="fa fa-pencil icon-only"></i>              
                                                                        </button>   
                                                                        <button className="btn btn-xs btn-danger btn-user-delete media-actions" type="button" onClick={() => deleteGuest(m)}>                 
                                                                            <i className="fa fa-trash-o icon-only"></i>              
                                                                        </button>   
                                                                    </div>
                                                                </div>
                                                            </div>                                                           
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" data-dismiss="modal" onClick={onClose}> Close </button>
                    </div>
                </div>
            </div>
        </div>

        {openModal && ( 
            <AddGuest
                show             = {!!openModal}
                selectedGuest    = {openModal}
                refetchUserGuest = {fetchUsersGuest}              
                onSelectGuest    = {v => onSelectGuest(v)}                
                onDismissModal   = {() => setOpenModal(false) }
            />)
        }
    </>)
}

export default GuestModal;