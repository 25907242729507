import { axiosGet } from "./axiosFunctions";

const getOrderByPaymentOrderIDUrl                            = '/api/payment/getOrderByPaymentOrderID';


export const getOrderByPaymentOrderID = async (paymentOrderID) => {   
    const response = await axiosGet(getOrderByPaymentOrderIDUrl, { headers: '', params: {paymentOrderID}});
    return response;
}

