const htmlContent1 = 
`<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<style>
  body {
    font-family: Calibri;
    color: #222;
    font-size: 1.7rem;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
</head>
<body>
<h5><strong>TERMS & CONDITIONS </strong></h5>
<ol start="1">
  <li>
    <p>GENERAL CONDUCT:</p>
    <p>All members are expected to conduct themselves in a manner that reflects the values and principles of the Club. Members must:</p>
    <ul>
        <li>Treat other members, staff, and guests with respect.</li>
        <li>Refrain from engaging in disruptive or harmful behavior.</li>
        <li>Follow all instructions and guidelines provided by Club staff.</li>
    </ul>
  </li>

  <li>
    <p>USE OF FACILITIES:</p>
    <p>Members are entitled to use the Club's facilities in accordance with the following guidelines:</p>
    <ul>
        <li>Facilities are to be used during designated hours only.</li>
        <li>Members must check-in at the front desk upon arrival.</li>
        <li>Equipment and amenities should be used responsibly and returned to their proper place after use.</li>
        <li>Members are responsible for any damage caused to Club property.</li>
    </ul>
  </li>
  
  <li>
    <p>SAFETY AND SECURITY:</p>
    <p>The safety and security of all members are of utmost importance. Members must:</p>
    <ul>
        <li>Report any suspicious activity to Club staff immediately.</li>
        <li>Follow all posted safety rules and regulations.</li>
        <li>Not bring any prohibited items onto Club premises.</li>
        <li>Comply with emergency procedures and evacuations when necessary.</li>
    </ul>
  </li>
  
  <li>
    <p>PRIVACY POLICY:</p>
    <p>Members' personal information will be collected, used, and protected in accordance with the Club's privacy policy, which outlines the measures taken to ensure the confidentiality and security of personal data.</p>
  </li>
  
  <li>
    <p>DISPUTE RESOLUTION:</p>
    <p>Any disputes arising from the use of the Club's facilities or membership shall be resolved through the Club's internal dispute resolution process before seeking any external mediation or legal action.</p>
  </li>
</ol>

</body>
</html>
`;
const htmlContent2 = 
`<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>TERMS & CONDITIONS Form No: 1 - Part 2</title>
<style>
  body {
    font-family: Calibri;
    color: #222;
    font-size: 1.7rem;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
</head>
<body>
<ol start="6">
  <li>
    <p>SUBSCRIPTION DETAILS:</p>
    <p>The Club offers the following membership types:</p>
    <ul>
        <li>Individual Membership</li>
        <li>Family Membership</li>
        <li>Student Membership</li>
        <li>Corporate Membership</li>
    </ul>
    <p>Membership fees are as follows:</p>
    <ul>
        <li>Individual Membership: $50 per month</li>
        <li>Family Membership: $80 per month</li>
        <li>Student Membership: $30 per month</li>
        <li>Corporate Membership: Custom pricing available upon request</li>
    </ul>
    <p>All fees are payable on a monthly basis and are due on the first of each month. Members may choose to pay via credit card, bank transfer, or other approved methods.</p>
    <p>Members may cancel their membership at any time by providing written notice to the Club. Refunds will be issued as follows:</p>
    <ul>
        <li>Full refund if canceled within the first 7 days of membership.</li>
        <li>Partial refund if canceled within the first 15 days of membership.</li>
        <li>No refund if canceled after 15 days of membership.</li>
    </ul>
    <p>Memberships will automatically renew on a monthly basis unless the Member provides written notice of cancellation at least 10 days prior to the renewal date. Members will be notified of any changes to membership fees at least 30 days in advance.</p>
  </li>

  <li>
    <p>LIABILITY WAIVER:</p>
    <p>By becoming a member, you agree to release and hold harmless the Club, its staff, and its affiliates from any and all liability for any injury, loss, or damage that may occur as a result of your participation in Club activities or use of Club facilities.</p>
  </li>
  
  <li>
    <p>AMENDMENTS:</p>
    <p>The Club reserves the right to amend this Agreement at any time. Members will be notified of any changes via email or posted notices at the Club. Continued use of membership constitutes acceptance of the amended Agreement.</p>
  </li>
  
  <li>
    <p>GOVERNING LAW:</p>
    <p>This Agreement shall be governed by and construed in accordance with the laws of the [State/Country]. Any disputes arising out of or related to this Agreement shall be resolved through binding arbitration in accordance with the rules of the American Arbitration Association.</p>
  </li>
  
  <li>
    <p>TERMINATION OF MEMBERSHIP:</p>
    <p>The Club reserves the right to terminate the membership of any member who violates the terms of this Agreement or engages in conduct deemed inappropriate by the Club. In the event of termination, no refund will be issued for any remaining membership period.</p>
  </li>
  
  <li>
    <p>USE OF CLUB PROPERTY:</p>
    <p>Members are responsible for any damage to Club property caused by themselves or their guests. Members must report any damage immediately to Club staff and may be required to cover the cost of repairs or replacements.</p>
  </li>
</ol>

</body>
</html>
`;
 
const formHtmlList = [
    htmlContent1, htmlContent2 
]


export default formHtmlList;