import { useEffect, useState } from "react";
import swal from 'sweetalert2';
import { getTeeTimeBookMemberList, postFavouriteUser } from "../api/teeTimeAPI";
import defaultlogo from '../../assets/images/defaultlogo.jpg';
import { getAuthState } from "../../assets/scripts/login-util";

const imgUrl = 'https://golfezdev.s3.ap-south-1.amazonaws.com/Public/CLUBEZ/User/';

const AddMember = (props) => {

    const [members,         setMembers]         = useState([]);
    const [filteredMembers, setFilteredMembers] = useState([]);

    useEffect(() => {
        $('#modalMember').modal({ backdrop: 'static', keyboard: false });
        $(props.from).addClass('modal-blur'); 
        $('#modalMember').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal(false);
        });
        
        fetchActiveMembers();
    }, [])
   
    const fetchActiveMembers = async() => {
        try {
            const user = getAuthState('user');
            const { TeeTimeMembers } = await getTeeTimeBookMemberList();
                
            if(props.members && props.members.length > 0){  // removes selected member's              
                const filtered = TeeTimeMembers.filter( g => ( g.UserID != user?.id  ) && props.members.findIndex( f => ((f.UserID == g.UserID) && (f.UserType == 'User'))) === -1 );
                setMembers(filtered);
                setFilteredMembers(filtered.filter(m => m.IsFavourite == "Y"));                           
            }else{
                setMembers(TeeTimeMembers.filter(g => g.UserID != user?.id ));
                setFilteredMembers(TeeTimeMembers.filter(m => ( m.UserID != user?.id ) && m.IsFavourite == "Y"));
            }
        } catch (err) {
            swal.fire({ icon: "error", titleText: "Error!", text: err.message });
            return;  
        }
    }

    const addPlayerHandler = (player) => {
        if(props.from == 'header') return;

        props.setMembers(d => [...d, { UserID: player.UserID, UserName: `${player.FirstName} ${player?.MiddleName} ${player.LastName} (${player.MembershipNo})`, UserType: "User"}]);
        $('#modalMember').modal('hide');
        $(props.from).removeClass('modal-blur');
    }

    const onMemberFavourite = async(p) => {
        try {            
            const type = (p.IsFavourite === 'N') ? 'a' : 'd'; 
            await postFavouriteUser(p.UserID, type);
           
            $('#txtSearch').val('');
            fetchActiveMembers();            
        } catch (err) {           
            swal.fire({ icon: "error", titleText: "Error!", text: err.message });
            return;  
        }
    }

    const onMemberSearch = (searchTerm) => {
        if(!searchTerm || searchTerm ==='') return setFilteredMembers(members.filter(m => m.IsFavourite == "Y"));

        //filter as per searched
        const filtered = members.filter(m => {
            let matchString = `${m.FirstName} ${m?.MiddleName} ${m.LastName} ${m.MembershipNo}`;
            let matchFound  = 0;
            var searchArr   = searchTerm.split(' ');
            
            searchArr.forEach(t => matchFound += matchString.toLowerCase().includes(t.toLowerCase()) ? 1 : 0);

            return matchFound === searchArr.length;
        });
        
        setFilteredMembers(filtered);
    }   

    const onClose =()=> $(props.from).removeClass('modal-blur');

    return(
        <>
            <div id="modalMember" className="modal fade modal-responsive" tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header padding-b0">
                            <button type="button" className="close" data-dismiss="modal"><span aria-hidden="true"  onClick={onClose}>&times;</span></button>
                            <h5 className="modal-title" id="member-modal-title">Search Member</h5>
                        </div>
                        <div className="modal-body overflow-x- padding-t0">

                            <div id="ModalMemberHolder">
                                <div className="row sticky-search">
                                    <div className="col-sm-10 col-xs-8">
                                        <div className="form-group">
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                id="txtSearch" 
                                                name="txtSearch"
                                                placeholder="Search Member..."
                                                onChange={({target}) => onMemberSearch(target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-2 col-xs-2">
                                        <button type="button" className="btn btn-default" id="btnSearch" data-tab="search" >Search </button>
                                    </div>
                                </div>
                                
                                <div id="messageModelMember"></div>

                                <div className="row " id="memberContainer">
                                    <div className="col-sx-12">
                                        <table id="table-member" className="table table-bordered table-hover ">
                                            <tbody>
                                                {filteredMembers.length > 0 && filteredMembers.map((m, i) => (
                                                    <tr key={m.UserID}>
                                                        <td>
                                                            <div className="media member-slot" onClick={() =>  addPlayerHandler(m)}>
                                                                <div className="media-left">
                                                                {m.Photo && m.Photo != '' ? 
                                                                    <img src={`${imgUrl}${m.Photo}`} alt="user-profile" className="media-object img-circle img-user-list-sm"/> : 
                                                                    <img src={defaultlogo} alt="user-profile" className="media-object img-circle img-user-list-sm"/>
                                                                }
                                                                </div>
                                                                <div className="media-body vertical-align-middle">
                                                                    <p className="user-detail margin-b0">
                                                                        <span className="name"> {`${m.FirstName} ${m?.MiddleName} ${m.LastName} (${m.MembershipNo})`} </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="media-icon">
                                                            <i className={`fa ${m.IsFavourite == "Y" ? 'fa-star': 'fa-star-o'}  favIcon`} onClick={()=> onMemberFavourite(m)} > </i>                                                         
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal" onClick={onClose}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddMember;