import React, { useState, useEffect } from 'react';
import bannerLogo from '../../assets/images/CLUBEZ_Login_logo.png';
import poweredBy from '../../assets/images/CLUBEZ-powered_PNG.png';
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import { NavLink, useHistory } from 'react-router-dom';
import { registerUser, verifyMobileNumber } from '../api/loginAPI';

const Register = (props) => {

    const [registerData,           setRegisterData]           = useState({});
    const [disabledInput,          setDisabledInput]          = useState(false);
    const [showPassword,           setShowPassword]           = useState(false);
    const history 				                              = useHistory();

    useEffect(() => {
        validationAttachments();
    }, []);

    useEffect(() => {
        if (registerData && registerData.Mobile && registerData.Mobile.length == 10) {
            verifyMobile(registerData.Mobile);
        }
    }, [registerData.Mobile]);

    const validationAttachments = () => {
        $('#ControlHolder').bootstrapValidator({
            fields: {
                txtMobile: {
                    validators: {
                        notEmpty: {
                            message: 'Please enter mobile no.'
                        },
                        numeric: {
                            message: 'Number is required'
                        },
                        stringLength: {
                            max: 10,
                            min: 10,
                            message: 'Enter a 10 digit valid mobile number'
                        }
                    }
                },
                txtPassword: {
                    validators: {
                        notEmpty: {
                            message: 'Please enter password'
                        },
                        stringLength: {
                            min: 5,
                            message: 'Password should be at least 5 characters long'
                        }
                    }
                },
                txtConfirmPassword: {
                    validators: {
                        notEmpty: {
                            message: 'Please enter password'
                        },
                        identical: {
                            field: 'txtPassword',
                            message: 'Confirm password missmatch with password'
                        }
                    }
                },

            }
        });
    }

    const registerDataHandler = (key, value)=>{
        setRegisterData((d)=> { return {...d, [key] : value} });
    };

    const resetForm = () => {
        setRegisterData(d => ({
            Mobile: d.Mobile
        }))
        setDisabledInput(false);
        $('#ControlHolder').data('bootstrapValidator').resetForm();
    };

    const verifyMobile = async (mobile)=>{
        try {
            $('#btnRegister').attr('disabled', true);
            const response = await verifyMobileNumber(mobile);
            setRegisterData(d => ({
                ...d,
                ...response.User,
                FullName: response.User.FirstName + " " + response.User.LastName,
                Email: response.User.Email
            }))
            $('#btnRegister').removeAttr('disabled');
            setDisabledInput(true)

        } catch (err) {
            resetForm();
            swal.fire({
                icon              : "error",
                titleText         : "Error!",
                text              : err.message
              })
            return;  
        }
    };

    const registerHandler = async (e) => {
        e.preventDefault();

        $('#ControlHolder').data('bootstrapValidator').validate();
        if ($('#ControlHolder').data('bootstrapValidator').isValid()) {
            try {
                swal.fire({
                    titleText           : 'Loading',
                    text                : 'Please Wait...',
                    showConfirmButton   : false,
                    allowOutsideClick   : false,
                    onOpen: () => {
                        swal.showLoading();
                    }
                });
                const response = await registerUser(registerData);
                swal.close();
                $.session.set('Code', response.Session);
                $.session.set('Mobile', response.User.Mobile);
                $.session.set('UserID', response.User.ID);
                history.push('/verify-otp');
            } catch (err) {
                swal.close();
                swal.fire({
                    icon              : "error",
                    titleText         : "Error!",
                    text              : err.message
                  })
                return;  
            }
        }
    };

    return (
        <div className="container margin-t20">
            <div className="row">
                <div className="col-md-4 col-md-offset-4">
                    <div className="login-panel">
                        <NavLink to={'/login'}>
                            <img className="img-responsive img-login" src={bannerLogo} alt="" />
                        </NavLink>
                    </div>
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h3 className="panel-title">Registration</h3>
                        </div>
                        <div className="panel-body">
                            <div id="registerForm" role="form">
                                <div id="ControlHolder">
                                    <div className="form-group">
                                        <input 
                                            onChange={({ target }) => registerDataHandler('Mobile', target.value)}
                                            value={ registerData.Mobile || '' }
                                            id="txtMobile" 
                                            name="txtMobile" 
                                            className="form-control" 
                                            type="number" 
                                            placeholder="Mobile" 
                                            autoFocus="autofocus"
                                        />
                                    </div>
                                    <div className="form-group has-feedback">
                                        <input 
                                            onChange={({ target }) => registerDataHandler('FullName', target.value)}
                                            value={ registerData.FullName || '' }
                                            id="txtFullName" 
                                            name="txtFullName"
                                            disabled={disabledInput}
                                            className="form-control" 
                                            type="text" 
                                            placeholder="Name" 
                                        />
                                        {disabledInput && <span className="glyphicon glyphicon-lock form-control-feedback icLock"></span>}
                                    </div>
                                    <div className="form-group has-feedback">
                                        <input 
                                            onChange={({ target }) => registerDataHandler('Email', target.value)}
                                            value={ registerData.Email || '' }
                                            id="txtEmail" 
                                            name="txtEmail" 
                                            disabled={disabledInput}
                                            className="form-control" 
                                            type="text" 
                                            placeholder="Email" 
                                        />
                                        {disabledInput && <span className="glyphicon glyphicon-lock form-control-feedback icLock"></span>}
                                    </div>                                 
                                    <div className="form-group">
                                        <input 
                                            onChange={({ target }) => registerDataHandler('Password', target.value)}
                                            value={ registerData.Password || '' }
                                            id="txtPassword" 
                                            name="txtPassword" 
                                            className="form-control" 
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder="Password" 
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input 
                                            onChange={({ target }) => registerDataHandler('ConfirmPassword', target.value)}
                                            value={ registerData.ConfirmPassword || '' }
                                            id="txtConfirmPassword" 
                                            name="txtConfirmPassword" 
                                            className="form-control" 
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder="Confirm Password" 
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="inline">
                                            <input
                                                defaultChecked={showPassword ? 'checked' : ''}
                                                onChange={(event) => setShowPassword(event.target.checked)} 
                                                type="checkbox" 
                                                className="ace" 
                                                id="chkShowPassword" 
                                                name="chkShowPassword"
                                            />
                                            <span className="lbl">Show Password</span>
                                        </label>
                                    </div>
                                </div>                               
                                <div className="clearfix form-actions">
                                    <button 
                                        type="button" 
                                        id="btnRegister" 
                                        name="btnRegister" 
                                        onClick={registerHandler}
                                        className="btn btn-lg btn-success btn-block">Register
                                    </button><br />
                                    <NavLink to={'/login'} className="lbl text-center">Already have an account? Login</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span className="container-power-login">                        
                        <a className="power" href='https://golfezapp.com/' target="_blank">
                             <img src={poweredBy} alt=""/>
                        </a>
                    </span>
                </div>
            </div>
            {/* {otpModal &&  <NavLink />} */}
        </div>
    )

}

export default Register;